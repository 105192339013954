import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p2 from 'assests/Photos/ClassAssembly/2023/11C/1.jpg';
// import p1 from 'assests/Photos/ClassAssembly/2023/11C/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/11C/3.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly11C2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/11C/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/11C/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/11C/3.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 3,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 3,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 3,
    },
    
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘MIND-A SPECTACULAR CIRCUS’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 11C   Date: 18 AUGUST 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 11C, National Public School, Yeshwanthpur presented their class assembly on 18 August, Friday titled ‘Mind-A Spectacular Circus’.  It was presented in the form of a dance drama wherein the students brought out the impact of perspectives of mind in a student’s life. The dance drama displayed the internal conflict of Good versus Evil in the minds of students as they navigate daily life. 
        <br />
        The message conveyed was that different situations can be perceived in different ways and these perceptions influence our decisions. Ultimately the choice lies within us and decisions need to be looked at in a logical manner with no emotions clouding the judgement.
        <br />
        The assembly must have surely left an inedible mark on the minds of the young generation who will take our country to greater heights by making judicious choices. 
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “MIND IS A CIRCUS NOT A MESS”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 150}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly11C2023;